export const PAGES = {
  HOME: { NAME: "Home", URL: "/" },
  ABOUT: { NAME: "About Us", URL: "/about" },
  WHY_CHOOSE_US: { NAME: "Why Choose Us", URL: "/why-choose-us" },
  SERVICES: { NAME: "Services", URL: "/services" },
  PAYMENT_SOLUTION: { NAME: "Payment Solution", URL: "/payment-solution" },
  BUSINESS_ENERGY: { NAME: "Business Energy", URL: "/business-energy" },
  MERCHANT_CASH_ADVANCE: {
    NAME: "Merchant Cash Advance",
    URL: "/merchant-cash-advance",
  },
  DIGITAL_MARKETING: {
    NAME: "Digital Marketing",
    URL: "/digital-marketing",
  },
  CONTACT_US: { NAME: "Contact Us", URL: "/contact-us" },
  PAGE_NOT_FOUND: { NAME: "Page Not Found", URL: "/page-not-found" },
};

export const keyToken = "#Save4u"+new Date().getFullYear();
const imagePath = process.env.PUBLIC_URL + "img/";
export const IMAGES = {
  LOGO: process.env.PUBLIC_URL + "logo.png",
  SLIDER1: {
    IMAGE_URL: "carousel-3.jpg",
    IMAGE_ALT: "Slider 1 image",
  },
};

export const SLIDER_IMAGES = [
  {
    imgURL: imagePath + "carousel-1.jpg",
    imgAlt: "img-1",
  },
  {
    imgURL: imagePath + "carousel-2.jpg",
    imgAlt: "img-2",
  },
  {
    imgURL: imagePath + "carousel-3.jpg",
    imgAlt: "img-3",
  },
];
