import React from 'react'

export default function Loader() {
  return (
    <div className='loader text-center'>
        <p>
            <span><i className="fas fa-duotone fa-spinner fa-4x"></i> </span>
            <span className='fs-2 fw-bold'>Loading...</span>
        </p>
    </div>
  )
}
