import { Suspense, lazy } from 'react';
// import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PAGES } from "./common/constants";
import Loader from './components/Loader';

const Home = lazy(() => import ("./Pages/Home"));
const About = lazy(() => import ("./Pages/About"));
// const Services = lazy(() => import ("./Pages/Services"));
const PageNotFound = lazy(() => import ("./Pages/PageNotFound"));
const WhyChooseUs = lazy(() => import ("./Pages/WhyChooseUs"));
const PaymentSolution = lazy(() => import ("./Pages/PaymentSolution"));
const BusinessEnergy = lazy(() => import ("./Pages/BusinessEnergy"));
const MerchantCashAdvance = lazy(() => import ("./Pages/MerchantCashAdvance"));
const DigitalMarketing = lazy(() => import ("./Pages/DigitalMarketing"));
const Contact = lazy(() => import ("./Pages/Contact"));

function App() {
  return (
    <BrowserRouter>
    <Suspense fallback={<Loader/>}>
      <Routes>
        <Route path={PAGES.HOME.URL} element={<Home />} />
        <Route path={PAGES.ABOUT.URL} element={<About />} />
        {/* <Route path={PAGES.SERVICES.URL} element={<Services />} /> */}
        <Route path={PAGES.WHY_CHOOSE_US.URL} element={<WhyChooseUs />} />
        <Route path={PAGES.PAYMENT_SOLUTION.URL} element={<PaymentSolution />} />
        <Route path={PAGES.BUSINESS_ENERGY.URL} element={<BusinessEnergy />} />
        <Route path={PAGES.MERCHANT_CASH_ADVANCE.URL} element={<MerchantCashAdvance />} />
        <Route path={PAGES.DIGITAL_MARKETING.URL} element={<DigitalMarketing />} />
        <Route path={PAGES.CONTACT_US.URL} element={<Contact />} />
        <Route path={PAGES.PAGE_NOT_FOUND.URL} element={<PageNotFound />} />
      </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
